/* Variables that might be used by other variable partials */
/* EXTENDED */
/* END EXTENDED */
@import url("https:///fonts.googleapis.com/css2?family=Inter:wght@400;450;500;550;600;700;800&display=swap");
:root {
  --surface-a: white;
  --surface-d: #dee2e6;
  --text-color: #2d3748;
  --text-color-secondary: #718096;
  --primary-color: #4299e1;
  --primary-color-text: #ffffff;
  --font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  /* Expose here what is needed by angular component style or tailwind utility */
  /* EXTENDED */
  --fontFamilySecondary: Helvetica, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --mainNavBg: radial-gradient(264.06% 35138.67% at 11.77% -50%, #305aba 0%, #3e69ca 100%);
  --mainNavActiveBg: rgb(71, 112, 205);
  --mainNavHoverBg: rgba(71, 112, 205, 0.7);
  --borderColor300: #e2e8f0;
  --borderRadius: 6px;
  --sectionNumberConnectorColor: #f7fafc;
  --errorColor: #dc2626;
  --surfaceColor0: white;
  --surfaceColor100: #f6f6f6;
  --surfaceColor200: #fff;
  --primaryTextColor0: #ffffff;
  --primaryTextColor50: #f9fafb;
  --primaryTextColor100: #f7fafc;
  --primaryTextColor200: #edf2f7;
  --primaryTextColor300: #e2e8f0;
  --primaryTextColor400: #cbd5e0;
  --primaryTextColor450: #c6ccd5;
  --primaryTextColor500: #a0aec0;
  --primaryTextColor600: #718096;
  --primaryTextColor700: #4a5568;
  --primaryTextColor800: #2d3748;
  --primaryTextColor900: #111827;
  --primaryNewTextColor0: #ffffff;
  --primaryNewTextColor50: #f9fafb;
  --primaryNewTextColor100: #f3f4f6;
  --primaryNewTextColor200: #e5e7eb;
  --primaryNewTextColor300: #d1d5db;
  --primaryNewTextColor400: #9ca3af;
  --primaryNewTextColor450: #89909b;
  --primaryNewTextColor500: #6b7280;
  --primaryNewTextColor600: #4b5563;
  --primaryNewTextColor700: #374151;
  --primaryNewTextColor800: #1f2937;
  --primaryNewTextColor900: #111827;
  /* END EXTENDED */
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 0.2s forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 0.2s forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: rgba(0, 0, 0, 0.4);
  }
  to {
    background-color: transparent;
  }
}
.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-error,
.p-invalid {
  color: #dc2626;
}

.p-text-secondary {
  color: #718096;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 0.875rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 6px;
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead {
  background: var(--surfaceColor200);
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.5rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 2.857rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.5rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #4299e1;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  color: #2d3748;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #4299e1;
  color: #ffffff;
  border-radius: 6px;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete.p-error > .p-inputtext, .p-autocomplete.p-invalid > .p-inputtext {
  border-color: #dc2626;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #dc2626;
}

.p-autocomplete-panel {
  background: white;
  color: #2d3748;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #2d3748;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #2d3748;
  background: #e9ecef;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}

.p-calendar.p-error > .p-inputtext, .p-calendar.p-invalid > .p-inputtext {
  border-color: #dc2626;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #dc2626;
}

.p-datepicker {
  padding: 0.5rem;
  background: #ffffff;
  color: #2d3748;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #2d3748;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 0.875rem;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  padding: 0.5rem;
}
.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #2d3748;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.286rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.429rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #dee2e6;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #cbd5e0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: #4299e1;
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.5rem 0.5rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #a0aec0;
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #e2e8f0;
  width: 2.357rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-cascadeselect.p-error, .p-cascadeselect.p-invalid {
  border-color: #dc2626;
}

.p-cascadeselect-panel {
  background: white;
  color: #2d3748;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #2d3748;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.5rem 1rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #2d3748;
  background: #e9ecef;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

.p-input-filled .p-cascadeselect {
  background: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #2d3748;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #4299e1;
  background: #4299e1;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #4299e1;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #3182ce;
  background: #3182ce;
  color: #ffffff;
}
.p-checkbox.p-error > .p-checkbox-box, .p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #dc2626;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #dc2626;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #4299e1;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #3182ce;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.5rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #4299e1;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #4299e1;
  color: #ffffff;
  border-radius: 6px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  color: #2d3748;
  padding: 0;
  margin: 0;
}
.p-chips.p-error > .p-inputtext, .p-chips.p-invalid > .p-inputtext {
  border-color: #dc2626;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #dc2626;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #cbd5e0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #4299e1;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.5rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #a0aec0;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #e2e8f0;
  width: 2.357rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #e2e8f0;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: white;
  color: #2d3748;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #2d3748;
  background: #f7fafc;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.5rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.5rem;
  color: #e2e8f0;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #2d3748;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #2d3748;
  background: #e9ecef;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1rem;
  color: #2d3748;
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #2d3748;
  background: #ffffff;
  font-weight: 600;
}
.p-dropdown-panel.p-error, .p-dropdown-panel.p-invalid {
  border-color: #dc2626;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #dc2626;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: white;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 0.5rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #2d3748;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #2d3748;
  background: #e9ecef;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #2d3748;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #4299e1;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #4299e1;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #4299e1;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #4299e1;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #718096;
  border-top: 1px solid #cbd5e0;
  border-left: 1px solid #cbd5e0;
  border-bottom: 1px solid #cbd5e0;
  padding: 0.5rem 0.5rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #cbd5e0;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #dc2626;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #dc2626;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
  background: #ffffff;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #4299e1;
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #367bec;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #c3cad2;
}
.p-inputswitch.p-error, .p-inputswitch.p-invalid {
  border-color: #dc2626;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #dc2626;
}

.p-inputtext {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  color: #2d3748;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #cbd5e0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
}
.p-inputtext:enabled:hover {
  border-color: #4299e1;
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-inputtext.p-error, .p-inputtext.p-invalid, .p-inputtext.ng-dirty.ng-invalid {
  border-color: #dc2626;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.765625rem;
  padding: 0.4375rem 0.4375rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.09375rem;
  padding: 0.625rem 0.625rem;
}

.p-float-label > label {
  left: 0.5rem;
  color: #a0aec0;
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #dc2626;
}

.p-input-icon-left > i:first-of-type {
  left: 0.5rem;
  color: #e2e8f0;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.5rem;
  color: #e2e8f0;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2rem;
}

::-webkit-input-placeholder {
  color: #a0aec0;
}

:-moz-placeholder {
  color: #a0aec0;
}

::-moz-placeholder {
  color: #a0aec0;
}

:-ms-input-placeholder {
  color: #a0aec0;
}

.p-input-filled .p-inputtext {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #f8f9fa;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.765625rem;
  padding: 0.4375rem 0.4375rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.09375rem;
  padding: 0.625rem 0.625rem;
}

.p-listbox {
  background: white;
  color: #2d3748;
  border: 1px solid #cbd5e0;
  border-radius: 6px;
}
.p-listbox .p-listbox-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #2d3748;
  background: #f7fafc;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.5rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.5rem;
  color: #e2e8f0;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #2d3748;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #2d3748;
  background: #e9ecef;
}
.p-listbox.p-error, .p-listbox.p-invalid {
  border-color: #dc2626;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #dc2626;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #cbd5e0;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: #4299e1;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: #a0aec0;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  background: #4299e1;
  color: #ffffff;
  border-radius: 6px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #e2e8f0;
  width: 2.357rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-multiselect.p-error, .p-multiselect.p-invalid {
  border-color: #dc2626;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.5rem;
}

.p-multiselect-panel {
  background: white;
  color: #2d3748;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #2d3748;
  background: #f7fafc;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.5rem;
  color: #e2e8f0;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #2d3748;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #2d3748;
  background: #e9ecef;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1rem;
  color: #2d3748;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #dc2626;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.p-password-panel {
  padding: 1.75rem;
  background: white;
  color: #2d3748;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
}

.p-radiobutton {
  width: 16px;
  height: 16px;
}
.p-radiobutton .p-radiobutton-box {
  border: 1px solid #cbd5e0;
  background: #ffffff;
  width: 16px;
  height: 16px;
  color: #2d3748;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #4299e1;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: #6ea6f3;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  transition-duration: 0.2s;
  background-color: #f6f6f6;
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #4299e1;
  background: #4299e1;
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #3182ce;
  background: #3182ce;
  color: #f6f6f6;
}
.p-radiobutton.p-error > .p-radiobutton-box, .p-radiobutton.p-invalid > .p-radiobutton-box {
  border-color: #dc2626;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #dc2626;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #4299e1;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #3182ce;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
  border-color: #f6f6f6;
}

.p-rating .p-rating-icon {
  color: #2d3748;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}
.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
  color: #4299e1;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #4299e1;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-highlight .p-rating .p-rating-icon {
  color: #ffffff;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  color: #2d3748;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #718096;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #2d3748;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #718096;
}
.p-selectbutton .p-button.p-highlight {
  background: #4299e1;
  border-color: #4299e1;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #367bec;
  border-color: #367bec;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-selectbutton.p-error > .p-button, .p-selectbutton.p-invalid > .p-button {
  border-color: #dc2626;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #dc2626;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 6px;
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid #4299e1;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-slider .p-slider-range {
  background: #4299e1;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #4299e1;
  border-color: #4299e1;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #2d3748;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #718096;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #2d3748;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #718096;
}
.p-togglebutton.p-button.p-highlight {
  background: #4299e1;
  border-color: #4299e1;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover {
  background: #367bec;
  border-color: #367bec;
  color: #ffffff;
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}
.p-togglebutton.p-button.p-error, .p-togglebutton.p-button.p-invalid {
  border-color: #dc2626;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #dc2626;
}

.p-button {
  color: #ffffff;
  background: #4299e1;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-button:enabled:hover {
  background: #367bec;
  color: #ffffff;
  border-color: #367bec;
}
.p-button:enabled:active {
  background: #3182ce;
  color: #ffffff;
  border-color: #3182ce;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: #4299e1;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(66, 153, 225, 0.04);
  color: #4299e1;
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(66, 153, 225, 0.16);
  color: #4299e1;
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: transparent;
  color: #4299e1;
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: rgba(66, 153, 225, 0.04);
  color: #4299e1;
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: rgba(66, 153, 225, 0.16);
  color: #4299e1;
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 0.875rem;
  height: 0.875rem;
  line-height: 0.875rem;
  color: #4299e1;
  background-color: #ffffff;
}
.p-button.p-button-raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 0.765625rem;
  padding: 0.4375rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.09375rem;
  padding: 0.625rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #24292e;
  background: #fafbfc;
  border: 1px solid #cbd5e0;
}
.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #f6f6f7;
  color: black;
  border-color: #cbd5e0;
}
.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #e9e9ec;
  color: black;
  border-color: #455a64;
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #fafbfc;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(250, 251, 252, 0.04);
  color: #fafbfc;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(250, 251, 252, 0.16);
  color: #fafbfc;
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #fafbfc;
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(250, 251, 252, 0.04);
  border-color: transparent;
  color: #fafbfc;
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(250, 251, 252, 0.16);
  border-color: transparent;
  color: #fafbfc;
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #03a9f4;
  border: 1px solid #03a9f4;
}
.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #039be5;
  color: #ffffff;
  border-color: #039be5;
}
.p-button.p-button-info:enabled:focus,
.p-buttonset.p-button-info > .p-button:enabled:focus,
.p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #0288d1;
  color: #ffffff;
  border-color: #0288d1;
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #03a9f4;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(3, 169, 244, 0.04);
  color: #03a9f4;
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(3, 169, 244, 0.16);
  color: #03a9f4;
  border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #03a9f4;
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(3, 169, 244, 0.04);
  border-color: transparent;
  color: #03a9f4;
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(3, 169, 244, 0.16);
  border-color: transparent;
  color: #03a9f4;
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #10b981;
  border: 1px solid #4caf50;
}
.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #059669;
  color: #ffffff;
  border-color: #43a047;
}
.p-button.p-button-success:enabled:focus,
.p-buttonset.p-button-success > .p-button:enabled:focus,
.p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #047857;
  color: #ffffff;
  border-color: #388e3c;
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #10b981;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(16, 185, 129, 0.04);
  color: #10b981;
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(16, 185, 129, 0.16);
  color: #10b981;
  border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #10b981;
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(16, 185, 129, 0.04);
  border-color: transparent;
  color: #10b981;
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(16, 185, 129, 0.16);
  border-color: transparent;
  color: #10b981;
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: #ffffff;
  background: #ed8936;
  border: 1px solid #ed8936;
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #d97706;
  color: #ffffff;
  border-color: #d97706;
}
.p-button.p-button-warning:enabled:focus,
.p-buttonset.p-button-warning > .p-button:enabled:focus,
.p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #b45309;
  color: #ffffff;
  border-color: #b45309;
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ed8936;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(237, 137, 54, 0.04);
  color: #ed8936;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(237, 137, 54, 0.16);
  color: #ed8936;
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #ed8936;
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(237, 137, 54, 0.04);
  border-color: transparent;
  color: #ed8936;
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(237, 137, 54, 0.16);
  border-color: transparent;
  color: #ed8936;
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #9c27b0;
  border: 1px solid #9c27b0;
}
.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #8e24aa;
  color: #ffffff;
  border-color: #8e24aa;
}
.p-button.p-button-help:enabled:focus,
.p-buttonset.p-button-help > .p-button:enabled:focus,
.p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #7b1fa2;
  color: #ffffff;
  border-color: #7b1fa2;
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  color: #9c27b0;
  border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #9c27b0;
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(156, 39, 176, 0.04);
  border-color: transparent;
  color: #9c27b0;
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(156, 39, 176, 0.16);
  border-color: transparent;
  color: #9c27b0;
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #dc2626;
  border: 1px solid #f44336;
}
.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #b91c1c;
  color: #ffffff;
  border-color: #e53935;
}
.p-button.p-button-danger:enabled:focus,
.p-buttonset.p-button-danger > .p-button:enabled:focus,
.p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #991b1b;
  color: #ffffff;
  border-color: #991b1b;
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #dc2626;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(220, 38, 38, 0.04);
  color: #dc2626;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(220, 38, 38, 0.16);
  color: #dc2626;
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #dc2626;
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(220, 38, 38, 0.04);
  border-color: transparent;
  color: #dc2626;
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(220, 38, 38, 0.16);
  border-color: transparent;
  color: #dc2626;
}

.p-button.p-button-link {
  color: #3182ce;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #3182ce;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #3182ce;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #4299e1;
  color: #ffffff;
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #edf2f7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e2e8f0;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #2d3748;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-thead > tr > th.p-frozen-column.last-frozen-column-from-left {
  border-right: 1px solid #e2e8f0;
  z-index: 1;
  -webkit-box-shadow: 6px 0 10px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 6px 0 10px -4px rgba(0, 0, 0, 0.1);
}
.p-datatable .p-datatable-thead > tr > th.p-frozen-column.last-frozen-column-from-right {
  border-left: 1px solid #e2e8f0;
  z-index: 1;
  -webkit-box-shadow: -6px 0 10px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -6px 0 10px -4px rgba(0, 0, 0, 0.1);
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #edf2f7;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #2d3748;
  background: transparent;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #718096;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #4299e1;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #edf2f7;
  color: #2d3748;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #718096;
}
.p-datatable .p-sortable-column.p-highlight {
  background: #f7fafc;
  color: #4299e1;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #4299e1;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #edf2f7;
  color: #4299e1;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #4299e1;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: white;
  color: #2d3748;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: #6ea6f3;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e2e8f0;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #4299e1;
  color: #ffffff;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #4299e1;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #4299e1;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #edf2f7;
  color: #2d3748;
}
.p-datatable .p-column-resizer-helper {
  background: #4299e1;
}
.p-datatable .p-frozen-column.last-frozen-column-from-left {
  border-right: 1px solid #e2e8f0;
  z-index: 1;
  -webkit-box-shadow: 6px 0 10px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 6px 0 10px -4px rgba(0, 0, 0, 0.1);
}
.p-datatable .p-frozen-column.last-frozen-column-from-right {
  border-left: 1px solid #e2e8f0;
  z-index: 1;
  -webkit-box-shadow: -6px 0 10px -4px rgba(0, 0, 0, 0.1);
  box-shadow: -6px 0 10px -4px rgba(0, 0, 0, 0.1);
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #4299e1;
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #ffffff;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #ffffff;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}
.p-datatable .datatable-cell-pending {
  background-color: #ebf8ff !important;
}
.p-datatable .datatable-cell-changed-by-user {
  background-color: #90cdf4 !important;
}
.p-datatable .datatable-cell-changed-by-other {
  background-color: #fca5a5 !important;
}
.p-datatable .datatable-cell-pending-text {
  color: #2d3748 !important;
}
.p-datatable .datatable-row-changed {
  background-color: #bfdbfe !important;
}
.p-datatable .datatable-row-added-by-user {
  background-color: #bfdbfe !important;
}
.p-datatable .datatable-row-added-by-other {
  background-color: #fecaca !important;
}
.p-datatable .p-datatable-presentation-cell-changed {
  background-color: #90cdf4 !important;
}
.p-datatable .datatable-presentation-row-added {
  background-color: #bfdbfe !important;
}
.p-datatable .datatable-presentation-row-changed {
  background-color: #bfdbfe !important;
}
.p-datatable .datatable-row-delayed {
  background-color: #fee2e2 !important;
}
.p-datatable .datatable-row-map-selection {
  background-color: #edf2f7 !important;
}
.p-datatable .datatable-row-map-selection-text {
  color: #2d3748 !important;
}
.p-datatable .datable-cell-excel-import {
  background-color: #ebf8ff !important;
}
.p-datatable .datatable-frozen-header {
  background-color: white !important;
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: white;
  color: #2d3748;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #edf2f7;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #edf2f7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1.75rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  @apply ml-1 mt-[-2px];
  width: calc(2rem - 9px);
  height: calc(2rem - 9px);
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #e9ecef;
  color: #2d3748;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: rgb(71, 112, 205);
  background: #ebf8ff;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-column-filter-menu-button .pi-filter-icon {
  padding-top: 2px;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-column-filter-overlay {
  background: white;
  color: #2d3748;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #2d3748;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #2d3748;
  background: #e9ecef;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #2d3748;
  background: #f7fafc;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.75rem;
  border-bottom: 1px solid #dee2e6;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.75rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar .p-button {
  width: auto;
}

.fc .fc-view-container th {
  background: #f8f9fa;
  border: 1px solid #e2e8f0;
  color: #2d3748;
}
.fc .fc-view-container td.fc-widget-content {
  background: white;
  border: 1px solid #e2e8f0;
  color: #2d3748;
}
.fc .fc-view-container td.fc-head-container {
  border: 1px solid #e2e8f0;
}
.fc .fc-view-container .fc-row {
  border-right: 1px solid #e2e8f0;
}
.fc .fc-view-container .fc-event {
  background: #367bec;
  border: 1px solid #367bec;
  color: #ffffff;
}
.fc .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1px solid #e2e8f0;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #4299e1;
  border: 1px solid transparent;
  font-size: 0.875rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  display: flex;
  align-items: center;
}
.fc .fc-toolbar .fc-button:enabled:hover {
  background: #367bec;
  color: #ffffff;
  border-color: #367bec;
}
.fc .fc-toolbar .fc-button:enabled:active {
  background: #3182ce;
  color: #ffffff;
  border-color: #3182ce;
}
.fc .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "primeicons", sans-serif !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "primeicons", sans-serif !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #2d3748;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #2d3748;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #4299e1;
  border-color: #4299e1;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #367bec;
  border-color: #367bec;
  color: #ffffff;
}
.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  z-index: 1;
}
.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-orderlist .p-orderlist-controls {
  padding: 1.75rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #e2e8f0;
  padding: 1.5rem;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 500;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.5rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.5rem;
  color: #e2e8f0;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  padding: 0.5rem 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #2d3748;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #2d3748;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-orderlist .p-orderlist-list .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background-color: #207cc9;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }
  .p-orderlist .p-orderlist-controls {
    padding: 1.75rem;
    flex-direction: row;
  }
  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  padding: 1.75rem;
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ecef;
  color: #2d3748;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #4299e1;
  color: #ffffff;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #16578d;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #e2e8f0;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #e2e8f0;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-paginator {
  background: white;
  color: #718096;
  border: solid #e2e8f0;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: #718096;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e2e8f0;
  border-color: transparent;
  color: #718096;
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: #718096;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: #718096;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #4299e1;
  border-color: #4299e1;
  color: #ffffff;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e2e8f0;
  border-color: transparent;
  color: #718096;
}

.p-picklist .p-picklist-buttons {
  padding: 1.75rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #e2e8f0;
  padding: 1.5rem;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 500;
}
.p-picklist .p-picklist-filter-container {
  padding: 1.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.5rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.5rem;
  color: #e2e8f0;
}
.p-picklist .p-picklist-list {
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  padding: 0.5rem 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #2d3748;
  background: transparent;
  transition: transform 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #2d3748;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: #4299e1;
}
.p-picklist .p-picklist-list .p-picklist-droppoint.p-picklist-droppoint-highlight {
  background-color: #207cc9;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1rem;
  color: #2d3748;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }
  .p-picklist .p-picklist-buttons {
    padding: 1.75rem;
    flex-direction: row;
  }
  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "\e930";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "\e92c";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "\e933";
  }
  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "\e92f";
  }
}
.p-timeline .p-timeline-event-marker {
  border: 2px solid #4299e1;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #ffffff;
}
.p-timeline .p-timeline-event-connector {
  background: repeating-linear-gradient(0deg, #e2e8f0, #e2e8f0 5px, transparent 5px, transparent 10px);
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  padding: 1.75rem;
  border-radius: 6px;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 6px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #718096;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #2d3748;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #4299e1;
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #ffffff;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #2d3748;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #2d3748;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.5rem;
  color: #e2e8f0;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #207cc9;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background-color: white;
  color: #2d3748;
  padding: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #4299e1;
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #2d3748;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-treeselect-panel {
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background-color: white;
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #edf2f7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e2e8f0;
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: #2d3748;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #edf2f7;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #2d3748;
  background: transparent;
}
.p-treetable .p-sortable-column {
  outline-color: #6ea6f3;
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #718096;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #4299e1;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #edf2f7;
  color: #2d3748;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #718096;
}
.p-treetable .p-sortable-column.p-highlight {
  background: #f7fafc;
  color: #4299e1;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #4299e1;
}
.p-treetable .p-treetable-tbody > tr {
  background: white;
  color: #2d3748;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: #6ea6f3;
}
.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #e2e8f0;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #2d3748;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #4299e1;
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #ffffff;
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #ffffff;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #edf2f7;
  color: #2d3748;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #2d3748;
}
.p-treetable .p-column-resizer-helper {
  background: #4299e1;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: white;
  color: #2d3748;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #edf2f7;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  color: #2d3748;
  background: #f8f9fa;
  font-weight: 500;
  border-radius: 6px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #f2f4f6;
  border-color: 1px solid #e2e8f0;
  color: #2d3748;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: 1px solid #e2e8f0;
  color: #2d3748;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: 1px solid #e2e8f0;
  background: #f2f4f6;
  color: #2d3748;
}
.p-accordion .p-accordion-content {
  padding: 1.75rem;
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  border-top: 0;
  border-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-accordion:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-accordion:last-child .p-accordion-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-accordion .p-accordion-tab-main {
  background-color: rgb(249, 250, 251) !important;
}
.p-accordion .p-accordion-tab-child {
  background-color: white !important;
}

.p-card {
  background: white;
  color: #2d3748;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.p-card .p-card-body {
  padding: 1rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #718096;
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: white;
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px #e2e8f0;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px #e2e8f0;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  border-radius: 6px;
}
.p-fieldset .p-fieldset-legend {
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  color: #2d3748;
  background: #f8f9fa;
  font-weight: 500;
  border-radius: 6px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1.5rem;
  color: #2d3748;
  border-radius: 6px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #2d3748;
}
.p-fieldset .p-fieldset-content {
  padding: 1.75rem;
}

.p-panel .p-panel-header {
  border: 1px solid #e2e8f0;
  padding: 1.5rem;
  background: #f8f9fa;
  color: #2d3748;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 500;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}
.p-panel .p-panel-content {
  padding: 1.75rem;
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background: white;
  color: #2d3748;
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #e2e8f0;
  background: white;
  border-radius: 6px;
  color: #2d3748;
}
.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: #f8f9fa;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-tabview .p-tabview-nav {
  background: white;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background: white;
  color: #6c757d;
  padding: 1.25rem;
  font-weight: 600;
  transition: box-shadow 0.2s;
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: white;
  border-color: #9ba2aa;
  color: #718096;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: white;
  border-color: #4299e1;
  color: #111827;
  border-width: 0 0 4px 0 !important;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-panels {
  background: white;
  padding: 0 1.75rem 1.75rem 1.75rem;
  border: 0 none;
  color: #2d3748;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #e2e8f0;
  padding: 1.5rem;
  border-radius: 6px;
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: white;
  color: #2d3748;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1.75rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.5rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: white;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: white;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: white;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: white;
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 6px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 1px solid #dee2e6;
  background: #f7fafc;
  color: #2d3748;
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: white;
  color: #2d3748;
  padding: 0 1.5rem;
}
.p-dialog .p-dialog-footer {
  border-top: 1px solid #dee2e6;
  background: white;
  color: #2d3748;
  padding: 1.5rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.dialog-excel-header-background {
  background: #edf2f7;
}

.p-overlaypanel {
  background: white;
  color: #2d3748;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1.75rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: #4299e1;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #367bec;
  color: #ffffff;
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: white;
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: white;
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: white;
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: white;
}

.p-sidebar {
  background: white;
  color: #2d3748;
  padding: 1.75rem;
  border: 0 none;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}
.p-sidebar .p-sidebar-close {
  width: 2rem;
  height: 2rem;
  color: #718096;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-close:enabled:hover {
  color: #2d3748;
  border-color: transparent;
  background: #e9ecef;
}
.p-sidebar .p-sidebar-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
  background: #2d3748;
  color: #ffffff;
  padding: 0.5rem 0.5rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #2d3748;
  border-width: 0.25rem 0.25rem 0.25rem 0;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #2d3748;
  border-width: 0.25rem 0 0.25rem 0.25rem;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #2d3748;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #2d3748;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  color: #2d3748;
  border-bottom: 0 none;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-fileupload .p-fileupload-content {
  background: white;
  padding: 2rem 1.25rem;
  border: 1px solid #e2e8f0;
  color: #2d3748;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #367bec;
  color: #ffffff;
  border-color: #367bec;
}
.p-fileupload-choose:not(.p-disabled):active {
  background: #3182ce;
  color: #ffffff;
  border-color: #3182ce;
}

.p-breadcrumb {
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 0.8rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #63b3ed;
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #63b3ed;
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #a0aec0;
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #a0aec0;
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #718096;
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: white;
  color: #2d3748;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-contextmenu .p-menuitem {
  margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #dee2e6;
  border-radius: 6px;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 6px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #718096;
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-megamenu .p-megamenu-panel {
  background: white;
  color: #2d3748;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #2d3748;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-menu {
  padding: 0.25rem 0;
  background: white;
  color: #2d3748;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-menu.p-menu-overlay {
  background: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #2d3748;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menu .p-menuitem {
  margin: 0;
}
.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: #2d3748;
  border: 1px solid #dee2e6;
  border-radius: 6px;
}
.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 6px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: #718096;
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #718096;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: #718096;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: #718096;
    background: #e9ecef;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0px 0px 0px 1px #6ea6f3;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: white;
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #2d3748;
    border-radius: 0;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #2d3748;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #718096;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #718096;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #2d3748;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #718096;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #718096;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #6ea6f3;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  color: #2d3748;
  background: #f8f9fa;
  font-weight: 500;
  border-radius: 6px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #f2f4f6;
  border-color: 1px solid #e2e8f0;
  color: #2d3748;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #f8f9fa;
  border-color: 1px solid #e2e8f0;
  color: #2d3748;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: 1px solid #e2e8f0;
  background: #f2f4f6;
  color: #2d3748;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #e2e8f0;
  background: white;
  color: #2d3748;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: white;
  color: #2d3748;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-slidemenu.p-slidemenu-overlay {
  background: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #2d3748;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #718096;
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #2d3748;
}

.p-steps .p-steps-item .p-menuitem-link {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  background: #ffffff;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #2d3748;
  border: 1px solid #c8c8c8;
  background: #ffffff;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #718096;
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #4299e1;
  color: #ffffff;
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #2d3748;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: white;
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid transparent;
  border-width: 0 0 2px 0;
  border-color: transparent;
  background: white;
  color: #718096;
  padding: 1.5rem;
  font-weight: 500;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: white;
  border-color: #9ba2aa;
  color: #718096;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: white;
  border-color: #4299e1;
  color: #111827;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: white;
  color: #2d3748;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #2d3748;
  border-radius: 0;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #718096;
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #2d3748;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #718096;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #718096;
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #6ea6f3;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-menuitem {
  margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #2d3748;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #718096;
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.5rem 0.5rem;
  margin: 0;
  border-radius: 6px;
}
.p-inline-message.p-inline-message-info {
  background: #039be5;
  border: solid #027cb7;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message.p-inline-message-success {
  background: #43a047;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message.p-inline-message-warn {
  background: #ffb300;
  border: 0 none;
  border-width: 1px;
  color: #2d3748;
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #2d3748;
}
.p-inline-message.p-inline-message-error {
  background: #e53935;
  border: 0 none;
  border-width: 1px;
  color: #ffffff;
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #ffffff;
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 6px;
}
.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}
.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-message.p-message-info {
  background: #039be5;
  border: solid #027cb7;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-info .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-info .p-message-close {
  color: #ffffff;
}
.p-message.p-message-success {
  background: #43a047;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-success .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-success .p-message-close {
  color: #ffffff;
}
.p-message.p-message-warn {
  background: #ffb300;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #2d3748;
}
.p-message.p-message-warn .p-message-icon {
  color: #2d3748;
}
.p-message.p-message-warn .p-message-close {
  color: #2d3748;
}
.p-message.p-message-error {
  background: #e53935;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-message.p-message-error .p-message-icon {
  color: #ffffff;
}
.p-message.p-message-error .p-message-close {
  color: #ffffff;
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 0.9;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #039be5;
  border: solid #027cb7;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #43a047;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #ffb300;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #2d3748;
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #2d3748;
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #e53935;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #ffffff;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #ffffff;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #ebedef;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ebedef;
}
.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0.2);
  color: #aeb6bf;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
  margin: 0.5rem 0;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #ebedef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #ebedef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #4299e1;
  color: #ffffff;
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #4299e1;
  color: #ffffff;
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #aeb6bf;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #aeb6bf;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 6px;
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid white;
}

.p-badge {
  background: #4299e1;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: #fafbfc;
  color: #24292e;
}
.p-badge.p-badge-success {
  background-color: #10b981;
  color: #ffffff;
}
.p-badge.p-badge-info {
  background-color: #03a9f4;
  color: #ffffff;
}
.p-badge.p-badge-warning {
  background-color: #ed8936;
  color: #ffffff;
}
.p-badge.p-badge-danger {
  background-color: #dc2626;
  color: #ffffff;
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-chip {
  background-color: #dee2e6;
  color: #2d3748;
  border-radius: 16px;
  padding: 0 0.5rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.5rem;
}

.p-inplace .p-inplace-display {
  padding: 0.5rem 0.5rem;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #2d3748;
}
.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: 6px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #4299e1;
}
.p-progressbar .p-progressbar-label {
  color: #2d3748;
  line-height: 1.5rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-scrolltop.p-link {
  background: #4299e1;
}
.p-scrolltop.p-link:hover {
  background: #367bec;
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #f8f9fa;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 6px;
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #4299e1;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 6px;
}
.p-tag.p-tag-success {
  background-color: #10b981;
  color: #ffffff;
}
.p-tag.p-tag-info {
  background-color: #03a9f4;
  color: #ffffff;
}
.p-tag.p-tag-warning {
  background-color: #ed8936;
  color: #ffffff;
}
.p-tag.p-tag-danger {
  background-color: #dc2626;
  color: #ffffff;
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: white;
  color: #2d3748;
  border: 1px solid #e2e8f0;
  padding: 1.75rem;
}
.p-terminal .p-terminal-input {
  font-size: 0.875rem;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Customizations to the designer theme should be defined here */
/* For all colors used here variable must be extracted to _variables to be overridable in theme */
.p-button.p-component,
.p-selectbutton.p-component {
  @apply text-base;
}
.p-button.p-component:not(.p-button-text),
.p-selectbutton.p-component:not(.p-button-text) {
  box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.04);
}
.p-button.p-component:enabled:focus, .p-button.p-component:not(.p-disabled):focus,
.p-selectbutton.p-component:enabled:focus,
.p-selectbutton.p-component:not(.p-disabled):focus {
  border-color: #6ea6f3 !important;
  box-shadow: 0px 0px 0px 1px #6ea6f3;
  z-index: 1;
}
.p-button.p-component.p-button-sm,
.p-selectbutton.p-component.p-button-sm {
  @apply text-sm;
  line-height: 1rem;
  padding-left: 9px;
  padding-right: 9px;
}

.p-selectbutton.p-component .p-button-label {
  @apply text-sm;
  line-height: 1rem;
  padding-left: 9px;
  padding-right: 9px;
}

.p-button.p-button-secondary {
  border: 1px solid #cbd5e0;
}

.p-button.p-button-secondary .p-button-icon {
  color: #4299e1;
}

.p-button .p-button-icon.pi-plus,
.p-button.p-button-sm .p-button-icon.pi-plus {
  font-size: 0.65em;
  line-height: 0.875rem;
}

.p-button.p-button-sm .p-button-icon-left {
  padding-left: 4px;
}

.p-button-text {
  border: 0 !important;
}

p-checkbox:hover .p-checkbox.p-checkbox-danger:not(.p-checkbox-disabled) .p-checkbox-box {
  border-color: #f56565 !important;
}
p-checkbox:hover .p-checkbox.p-checkbox-warn:not(.p-checkbox-disabled) .p-checkbox-box {
  border-color: #ed8936 !important;
}
p-checkbox:hover .p-checkbox.p-checkbox-indigo:not(.p-checkbox-disabled) .p-checkbox-box {
  border-color: #6366f1 !important;
}
p-checkbox:hover .p-checkbox.p-checkbox-lime:not(.p-checkbox-disabled) .p-checkbox-box {
  border-color: #84cc16 !important;
}
p-checkbox:hover .p-checkbox.p-checkbox-green:not(.p-checkbox-disabled) .p-checkbox-box {
  border-color: #10b981 !important;
}
p-checkbox:hover .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
  border-color: #4299e1;
}

.p-checkbox.p-checkbox-danger .p-checkbox-box.p-highlight {
  border-color: #f56565 !important;
  background: #f56565 !important;
}
.p-checkbox.p-checkbox-danger:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #f56565 !important;
}
.p-checkbox.p-checkbox-danger:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #f56565 !important;
}
.p-checkbox.p-checkbox-danger:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #f56565 !important;
  background: #f56565 !important;
  color: #f56565 !important;
}
.p-checkbox.p-checkbox-warn .p-checkbox-box.p-highlight {
  border-color: #ed8936 !important;
  background: #ed8936 !important;
}
.p-checkbox.p-checkbox-warn:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ed8936 !important;
}
.p-checkbox.p-checkbox-warn:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #ed8936 !important;
}
.p-checkbox.p-checkbox-warn:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #ed8936 !important;
  background: #ed8936 !important;
  color: #ed8936 !important;
}
.p-checkbox.p-checkbox-indigo .p-checkbox-box.p-highlight {
  border-color: #6366f1 !important;
  background: #6366f1 !important;
}
.p-checkbox.p-checkbox-indigo:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #6366f1 !important;
}
.p-checkbox.p-checkbox-indigo:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #6366f1 !important;
}
.p-checkbox.p-checkbox-indigo:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #6366f1 !important;
  background: #6366f1 !important;
  color: #6366f1 !important;
}
.p-checkbox.p-checkbox-lime .p-checkbox-box.p-highlight {
  border-color: #84cc16 !important;
  background: #84cc16 !important;
}
.p-checkbox.p-checkbox-lime:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #84cc16 !important;
}
.p-checkbox.p-checkbox-lime:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #84cc16 !important;
}
.p-checkbox.p-checkbox-lime:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #84cc16 !important;
  background: #84cc16 !important;
  color: #84cc16 !important;
}
.p-checkbox.p-checkbox-green .p-checkbox-box.p-highlight {
  border-color: #10b981 !important;
  background: #10b981 !important;
}
.p-checkbox.p-checkbox-green:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #10b981 !important;
}
.p-checkbox.p-checkbox-green:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: #10b981 !important;
}
.p-checkbox.p-checkbox-green:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #10b981 !important;
  background: #10b981 !important;
  color: #10b981 !important;
}

.p-inputtext {
  transition: color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inputtext:enabled:hover {
  border-color: #4299e1;
}

.p-inputgroup.force-hover:hover .p-inputtext:enabled {
  border-color: #4299e1;
}

.p-inputtext,
.p-dropdown,
.p-multiselect {
  @apply shadow-sm;
}

::-webkit-input-placeholder {
  color: #a0aec0 !important;
}

:-moz-placeholder {
  color: #a0aec0 !important;
}

::-moz-placeholder {
  color: #a0aec0 !important;
}

:-ms-input-placeholder {
  color: #a0aec0 !important;
}

.p-autocomplete-dd button.p-autocomplete-dropdown,
.p-calendar-w-btn .p-datepicker-trigger,
.p-inputnumber-buttons-stacked .p-inputnumber-button,
.p-fileupload-choose {
  background: #fafbfc;
  color: #718096;
  border: 1px solid #cbd5e0 !important;
  box-shadow: none;
}
.p-autocomplete-dd button.p-autocomplete-dropdown .p-button-icon,
.p-calendar-w-btn .p-datepicker-trigger .p-button-icon,
.p-inputnumber-buttons-stacked .p-inputnumber-button .p-button-icon,
.p-fileupload-choose .p-button-icon {
  color: #a0aec0;
}
.p-autocomplete-dd button.p-autocomplete-dropdown:enabled:hover, .p-autocomplete-dd button.p-autocomplete-dropdown:not(.p-disabled):hover,
.p-calendar-w-btn .p-datepicker-trigger:enabled:hover,
.p-calendar-w-btn .p-datepicker-trigger:not(.p-disabled):hover,
.p-inputnumber-buttons-stacked .p-inputnumber-button:enabled:hover,
.p-inputnumber-buttons-stacked .p-inputnumber-button:not(.p-disabled):hover,
.p-fileupload-choose:enabled:hover,
.p-fileupload-choose:not(.p-disabled):hover {
  background: #f6f6f7;
  color: #718096;
}
.p-autocomplete-dd button.p-autocomplete-dropdown:enabled:active, .p-autocomplete-dd button.p-autocomplete-dropdown:not(.p-disabled):active,
.p-calendar-w-btn .p-datepicker-trigger:enabled:active,
.p-calendar-w-btn .p-datepicker-trigger:not(.p-disabled):active,
.p-inputnumber-buttons-stacked .p-inputnumber-button:enabled:active,
.p-inputnumber-buttons-stacked .p-inputnumber-button:not(.p-disabled):active,
.p-fileupload-choose:enabled:active,
.p-fileupload-choose:not(.p-disabled):active {
  background: #e9e9ec !important;
  color: #718096;
}

.p-autocomplete-dd button.p-autocomplete-dropdown:enabled:hover, .p-autocomplete-dd button.p-autocomplete-dropdown:not(.p-disabled):hover, .p-autocomplete-dd button.p-autocomplete-dropdown:enabled:focus, .p-autocomplete-dd button.p-autocomplete-dropdown:not(.p-disabled):focus,
.p-calendar-w-btn .p-datepicker-trigger:enabled:hover,
.p-calendar-w-btn .p-datepicker-trigger:not(.p-disabled):hover,
.p-calendar-w-btn .p-datepicker-trigger:enabled:focus,
.p-calendar-w-btn .p-datepicker-trigger:not(.p-disabled):focus,
.p-inputnumber-buttons-stacked .p-inputnumber-button:enabled:hover,
.p-inputnumber-buttons-stacked .p-inputnumber-button:not(.p-disabled):hover,
.p-inputnumber-buttons-stacked .p-inputnumber-button:enabled:focus,
.p-inputnumber-buttons-stacked .p-inputnumber-button:not(.p-disabled):focus {
  background: #f6f6f7;
  color: #718096;
  border-color: #cbd5e0 !important;
}

.p-inputnumber-buttons-stacked input.p-inputnumber-input,
.p-calendar-w-btn input.p-inputtext,
.p-autocomplete-dd .p-autocomplete-input {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.p-inputnumber-buttons-stacked .p-inputnumber-button-group,
.p-calendar-w-btn button.p-datepicker-trigger,
.p-autocomplete-dd button.p-autocomplete-dropdown {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  margin-left: 0px;
  border-bottom-right-radius: calc(var(--borderRadius) - 1px) !important;
  border-top-right-radius: calc(var(--borderRadius) - 1px) !important;
}
.p-inputnumber-buttons-stacked .p-inputnumber-button-group:after,
.p-calendar-w-btn button.p-datepicker-trigger:after,
.p-autocomplete-dd button.p-autocomplete-dropdown:after {
  content: none;
}

.p-inputnumber-buttons-stacked {
  position: relative;
}
.p-inputnumber-buttons-stacked .p-inputnumber-button {
  border-right: none !important;
}
.p-inputnumber-buttons-stacked .p-inputnumber-button:after {
  content: none;
}
.p-inputnumber-buttons-stacked .p-inputnumber-button:first-child {
  border-top: none !important;
  border-top-right-radius: calc(var(--borderRadius) - 1px) !important;
}
.p-inputnumber-buttons-stacked .p-inputnumber-button:last-child {
  border-top: none !important;
  border-bottom: none !important;
  border-bottom-right-radius: calc(var(--borderRadius) - 1px) !important;
}

.readonly.p-disabled,
.readonly.p-component:disabled {
  opacity: 1;
  @apply text-indigo-800 dark:text-indigo-300;
}
.readonly.p-disabled.p-inputtext,
.readonly.p-component:disabled.p-inputtext {
  @apply bg-blue-50 dark:bg-blue-900 font-medium;
}

.plu-radio-list {
  @apply overflow-auto rounded-lg border flex flex-col divide-y;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.03);
}
.plu-radio-list p-radiobutton {
  @apply relative;
}
.plu-radio-list p-radiobutton label {
  @apply pl-12 py-3 pr-4 m-0 w-full text-sm cursor-pointer !important;
}
.plu-radio-list p-radiobutton label.p-radiobutton-label-active {
  @apply bg-blue-50 dark:bg-blue-900 text-indigo-800 dark:text-indigo-300;
}
.plu-radio-list p-radiobutton .p-radiobutton {
  @apply absolute top-0 left-0 h-full w-10 flex items-center justify-center pl-4;
}
.plu-radio-list p-radiobutton .p-radiobutton.p-radiobutton-disabled {
  @apply cursor-default;
}
.plu-radio-list p-radiobutton:hover label:not(.p-disabled) {
  @apply bg-primary-200;
}
.plu-radio-list p-radiobutton:hover label:not(.p-disabled).p-radiobutton-label-active {
  @apply bg-primary-300;
}
.plu-radio-list p-radiobutton:hover .p-radiobutton .p-radiobutton-box {
  transition-duration: 0s;
}
.plu-radio-list p-radiobutton:hover .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight) {
  border-color: #4299e1;
}
.plu-radio-list p-radiobutton:hover .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled) {
  border-color: #3182ce;
  background: #3182ce;
  color: #f6f6f6;
}

.search-inputgroup input:hover,
.search-inputgroup button:hover {
  z-index: 2;
}
.search-inputgroup input:active, .search-inputgroup input:focus,
.search-inputgroup button:active,
.search-inputgroup button:focus {
  z-index: 3 !important;
}
.search-inputgroup input + button,
.search-inputgroup .p-inputgroup + button {
  margin-left: -1px !important;
}

p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext,
p-calendar.ng-touched.ng-invalid > .p-calendar > .p-inputtext,
p-checkbox.ng-touched.ng-invalid > .p-checkbox > .p-checkbox-box,
p-chips.ng-touched.ng-invalid > .p-chips > .p-inputtext,
p-dropdown.ng-touched.ng-invalid > .p-dropdown,
p-inputmask.ng-touched.ng-invalid > .p-inputtext,
p-inputnumber.ng-touched.ng-invalid > .p-inputnumber > .p-inputtext,
p-inputswitch.ng-touched.ng-invalid > .p-inputswitch,
.p-inputtext.p-invalid,
.p-inputtext.ng-touched.ng-invalid,
p-listbox.ng-touched.ng-invalid > .p-listbox,
p-multiselect.ng-touched.ng-invalid > .p-multiselect,
p-radiobutton.ng-touched.ng-invalid > .p-radiobutton > .p-radiobutton-box,
p-selectbutton.ng-touched.ng-invalid > .p-selectbutton > .p-button,
p-togglebutton.ng-touched.ng-invalid > .p-togglebutton.p-button {
  border-color: #dc2626;
}
p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext:focus, p-autocomplete.ng-touched.ng-invalid > .p-autocomplete > .p-inputtext.p-focus,
p-calendar.ng-touched.ng-invalid > .p-calendar > .p-inputtext:focus,
p-calendar.ng-touched.ng-invalid > .p-calendar > .p-inputtext.p-focus,
p-checkbox.ng-touched.ng-invalid > .p-checkbox > .p-checkbox-box:focus,
p-checkbox.ng-touched.ng-invalid > .p-checkbox > .p-checkbox-box.p-focus,
p-chips.ng-touched.ng-invalid > .p-chips > .p-inputtext:focus,
p-chips.ng-touched.ng-invalid > .p-chips > .p-inputtext.p-focus,
p-dropdown.ng-touched.ng-invalid > .p-dropdown:focus,
p-dropdown.ng-touched.ng-invalid > .p-dropdown.p-focus,
p-inputmask.ng-touched.ng-invalid > .p-inputtext:focus,
p-inputmask.ng-touched.ng-invalid > .p-inputtext.p-focus,
p-inputnumber.ng-touched.ng-invalid > .p-inputnumber > .p-inputtext:focus,
p-inputnumber.ng-touched.ng-invalid > .p-inputnumber > .p-inputtext.p-focus,
p-inputswitch.ng-touched.ng-invalid > .p-inputswitch:focus,
p-inputswitch.ng-touched.ng-invalid > .p-inputswitch.p-focus,
.p-inputtext.p-invalid:focus,
.p-inputtext.p-invalid.p-focus,
.p-inputtext.ng-touched.ng-invalid:focus,
.p-inputtext.ng-touched.ng-invalid.p-focus,
p-listbox.ng-touched.ng-invalid > .p-listbox:focus,
p-listbox.ng-touched.ng-invalid > .p-listbox.p-focus,
p-multiselect.ng-touched.ng-invalid > .p-multiselect:focus,
p-multiselect.ng-touched.ng-invalid > .p-multiselect.p-focus,
p-radiobutton.ng-touched.ng-invalid > .p-radiobutton > .p-radiobutton-box:focus,
p-radiobutton.ng-touched.ng-invalid > .p-radiobutton > .p-radiobutton-box.p-focus,
p-selectbutton.ng-touched.ng-invalid > .p-selectbutton > .p-button:focus,
p-selectbutton.ng-touched.ng-invalid > .p-selectbutton > .p-button.p-focus,
p-togglebutton.ng-touched.ng-invalid > .p-togglebutton.p-button:focus,
p-togglebutton.ng-touched.ng-invalid > .p-togglebutton.p-button.p-focus {
  box-shadow: 0 0 0 1px #dc2626 !important;
}

p-autocomplete.ng-touched.ng-invalid .p-autocomplete-dd button.p-autocomplete-dropdown:focus, p-autocomplete.ng-touched.ng-invalid .p-autocomplete-dd button.p-autocomplete-dropdown.p-focus,
p-calendar.ng-touched.ng-invalid .p-calendar-w-btn .p-datepicker-trigger:focus,
p-calendar.ng-touched.ng-invalid .p-calendar-w-btn .p-datepicker-trigger.p-focus,
p-inputnumber.ng-touched.ng-invalid .p-inputnumber-buttons-stacked .p-inputnumber-button:focus,
p-inputnumber.ng-touched.ng-invalid .p-inputnumber-buttons-stacked .p-inputnumber-button.p-focus {
  box-shadow: 0 0 0 1px #dc2626 !important;
}

.p-inputtext .p-float-label > .ng-invalid.ng-touched + label {
  color: #dc2626;
}

.p-dataview .override-data-view-header-quotations .pi-th-large::before {
  content: "\e949" !important;
}
.p-dataview .p-dataview-header {
  background: none;
}

.timeline-left-align .p-timeline-event-opposite {
  flex: 0;
}

.p-menu-item-link-lg .p-menuitem-link {
  @apply text-lg;
}

.p-breadcrumb ul li .p-menuitem-link {
  padding: 0.5rem 0.25rem;
  display: flex;
  align-items: center;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  font-size: 0.75em;
  padding-right: 12px;
  line-height: 11px;
  margin-top: 1px;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.25rem;
}

.p-breadcrumb-chevron::before {
  content: "/" !important;
  font-weight: 500;
  font-family: var(--font-family);
}

.p-tabview-panels-p-0 .p-tabview-panels {
  @apply p-0;
}

.p-inputgroup .p-float-label p-inputnumber .p-component,
.p-inputgroup .p-float-label p-dropdown .p-component {
  border-radius: 0;
}
.p-inputgroup .p-float-label p-inputnumber .p-component:hover,
.p-inputgroup .p-float-label p-dropdown .p-component:hover {
  z-index: 2;
}
.p-inputgroup .p-float-label p-inputnumber .p-component:active, .p-inputgroup .p-float-label p-inputnumber .p-component:focus, .p-inputgroup .p-float-label p-inputnumber .p-component.p-focus,
.p-inputgroup .p-float-label p-dropdown .p-component:active,
.p-inputgroup .p-float-label p-dropdown .p-component:focus,
.p-inputgroup .p-float-label p-dropdown .p-component.p-focus {
  z-index: 3 !important;
}
.p-inputgroup .p-float-label:first-child p-inputnumber .p-component,
.p-inputgroup .p-float-label:first-child p-dropdown .p-component {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-inputgroup .p-float-label:last-child p-inputnumber .p-component,
.p-inputgroup .p-float-label:last-child p-dropdown .p-component {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.card-tabview .p-tabview-nav {
  border-bottom: 1px solid #e2e8f0;
}
.card-tabview .p-tabview-nav .p-tabview-nav-link {
  @apply m-0 text-base leading-none pt-7 !important;
  border-bottom-width: 3px !important;
}

.shorter-tables .p-datatable-thead > tr > th {
  @apply py-3 !important;
}
.shorter-tables .p-datatable .p-datatable-tbody > tr > td {
  @apply py-2 !important;
}
.shorter-tables .p-datatable .p-paginator {
  @apply py-1 text-xs-plus !important;
}

.p-breadcrumb .p-menuitem-text,
.p-breadcrumb-chevron {
  @apply font-medium;
  @apply text-base !important;
}

.da-tab-separator {
  margin-left: 1.5rem;
  position: relative;
}
.da-tab-separator::before {
  content: "";
  position: absolute;
  left: -0.75rem;
  top: 0.6rem;
  bottom: 0.6rem;
  border-left: 1px solid #e2e8f0;
}

.chip-form-readonly {
  @apply bg-gray-100 text-gray-800 border border-gray-300;
}

.p-fileupload .p-fileupload-row > div {
  padding: 0.25rem;
}
.p-fileupload .p-fileupload-row > div:not(:nth-child(2)) {
  width: auto;
  flex: 0 0 auto;
}
.p-fileupload .p-fileupload-row > div > img {
  margin-right: 0.25rem;
}
.p-fileupload .p-fileupload-row > div:nth-child(2) {
  overflow-wrap: break-word;
}

.p-badge {
  @apply rounded-full !important;
}

.table-border-bottom-none .p-datatable-tbody > tr:last-child > td {
  border-bottom-width: 0;
}

.no-x-padding .p-datatable-wrapper > table th:first-child,
.no-x-padding .p-datatable-wrapper > table td:first-child {
  padding-left: 0;
}
.no-x-padding .p-datatable-wrapper > table th:last-child,
.no-x-padding .p-datatable-wrapper > table td:last-child {
  padding-right: 0;
}

.p-datatable .p-datatable-tbody > tr.dirty {
  @apply bg-blue-100 dark:bg-blue-900;
}

.gray-header-table .p-datatable .p-datatable-thead > tr > th {
  @apply font-medium text-primary-500 text-xs py-5;
}

.da-table .p-datatable .p-datatable-tbody > tr > td {
  @apply text-xs-plus py-2;
  height: 3.125rem;
  word-break: normal;
}
.da-table .p-badge {
  @apply h-5 leading-5 font-medium;
}

.po-table .p-datatable .p-datatable-tbody > tr > td {
  @apply font-medium;
}

.borderless-table .p-datatable .p-datatable-thead > tr > th {
  @apply font-medium text-sm text-primary-500 py-2;
}
.borderless-table .p-datatable .p-datatable-thead > tr > th,
.borderless-table .p-datatable .p-datatable-tbody > tr > td {
  @apply pl-0 pr-2;
}
.borderless-table .p-datatable .p-datatable-thead > tr > th:last-child,
.borderless-table .p-datatable .p-datatable-tbody > tr > td:last-child {
  @apply pr-0;
}
.borderless-table .p-datatable .p-datatable-tbody > tr > td {
  padding-top: 14px;
  padding-bottom: 14px;
  @apply border-0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  box-shadow: none !important;
  font-family: var(--font-family);
  letter-spacing: -0.039em;
  transition: border-color 0.2s, box-shadow 0.2s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 24px 18px;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight) .p-menuitem-link:not(.p-disabled):focus {
  border-color: #cbd5e0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  box-shadow: none !important;
  font-family: var(--font-family);
  letter-spacing: -0.039em;
  transition: border-color 0.2s, box-shadow 0.2s;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link:not(.p-disabled):focus {
  border-color: #cbd5e0;
}

.gray-search.p-input-icon-left > .p-inputtext {
  @apply pl-9;
}
.gray-search.p-input-icon-left > i:first-of-type {
  @apply left-3 text-primary-500;
}
.gray-search input {
  @apply bg-primary-100 border-primary-200;
}

p-chip .gradient-chip {
  @apply text-primary-700 border border-primary-400 bg-gradient-to-b from-primary rounded-full;
  --tw-gradient-to: #e5e5e5 !important;
}

.dark p-chip .gradient-chip {
  --tw-gradient-to: #3a3a3a !important;
}

p-chip .pi-chip-remove-icon.pi-times-circle {
  font-size: 0.8em;
  padding: 3px;
}
p-chip .pi-chip-remove-icon.pi-times-circle:before {
  content: "\e90b" !important;
}

.dark .p-button.p-button-secondary .p-button-icon {
  color: #4299e1;
}
.dark p-dynamicdialog .p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.p-inputgroup div + div {
  margin-left: -1px;
}
.p-inputgroup input {
  z-index: 1;
}
.p-inputgroup input:hover {
  z-index: 2;
}
.p-inputgroup input:active, .p-inputgroup input:focus {
  z-index: 3 !important;
}

.p-fileupload-choose {
  color: #24292e !important;
}

.progress-bar .p-progressbar {
  height: 4px;
  min-width: 20px;
  @apply rounded-full;
}
.progress-bar .p-progressbar .p-progressbar-value {
  @apply rounded-full  bg-teal-400;
}

.p-dialog {
  overflow: hidden;
}

.p-accordion-header-link:focus:not(:focus-visible) {
  outline: none;
  outline-offset: auto !important;
  box-shadow: none !important;
}

.hide-first-skeleton-column .table-loading-row td:first-child > * {
  visibility: hidden;
}
.hide-first-skeleton-column .table-loading-row td:last-child {
  padding-right: 2rem !important;
}

@media only screen and (max-width: 991px) {
  .p-datatable .p-datatable-tbody > tr > td {
    white-space: nowrap;
  }
  .p-datatable .p-datatable-thead > tr > th {
    white-space: nowrap;
  }
}
@media only screen and (max-width: 768px) {
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    padding: 12px 8px;
    font-size: 14px;
  }
  .p-tabview .p-tabview-nav {
    @apply px-3.5;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 18px 8px;
    font-size: 14px !important;
  }
  .p-menu {
    background: initial;
    border: none;
  }
  .p-menu .p-menuitem-link .p-menuitem-text {
    color: #fff;
  }
  .p-tabview .p-tabview-panels {
    @apply px-3.5;
  }
}
.p-overlaypanel.overlay-panel-full-width {
  right: 0px;
  width: 90%;
  left: 0px !important;
  max-width: 1100px;
  margin: auto !important;
}
.p-overlaypanel.overlay-panel-hide-arrow:before, .p-overlaypanel.overlay-panel-hide-arrow:after {
  display: none !important;
}
.p-overlaypanel.overlay-panel-common {
  @apply border border-primary-300 border-solid;
}
.p-overlaypanel.overlay-panel-common:after, .p-overlaypanel.overlay-panel-common:before {
  display: none;
}
.p-overlaypanel.remarks-panel {
  max-height: calc(100vh - 6rem);
  overflow: auto;
}
.p-overlaypanel.filters-panel {
  @apply border border-primary-300 border-solid;
}
.p-overlaypanel.filters-panel:after, .p-overlaypanel.filters-panel:before {
  border-bottom-color: transparent;
}
.p-overlaypanel.filters-panel .p-overlaypanel-content {
  @apply p-0 rounded-b !important;
}
.p-overlaypanel.filters-panel .filters-header {
  @apply bg-primary-100 flex justify-between items-center p-2 rounded-t;
}
.p-overlaypanel.filters-panel .filters-header .title {
  @apply text-sm-plus;
}
.p-overlaypanel.filters-panel .p-accordion p-accordiontab:last-child .p-accordion-content {
  @apply rounded-b !important;
}
.p-overlaypanel.filters-panel .p-accordion .p-accordion-header .p-accordion-header-link {
  @apply border-l-0 border-r-0;
  @apply p-3 bg-primary text-primary-700 rounded-none;
}
.p-overlaypanel.filters-panel .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  @apply text-primary-500;
}
.p-overlaypanel.filters-panel .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  @apply bg-primary-50;
}
.p-overlaypanel.filters-panel .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  @apply bg-primary;
}
.p-overlaypanel.filters-panel .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  @apply bg-primary-50;
}
.p-overlaypanel.filters-panel .p-accordion .p-accordion-content {
  @apply border-l-0 border-r-0;
  @apply py-3 px-2.5 rounded-none bg-primary-100;
}

.filters-active {
  @apply text-blue-600 border-blue-200 shadow font-semibold !important;
}
.filters-active .p-button-icon {
  @apply text-blue-600 !important;
}

.shipment-sidebar.p-sidebar-right {
  @apply py-3 pr-2;
}
.shipment-sidebar.p-sidebar-right .p-sidebar-content {
  overflow-y: scroll !important;
  @apply mt-1;
}
.shipment-sidebar.p-sidebar-right.p-sidebar-lg {
  width: 52rem;
}

@media screen and (max-width: 52em) {
  .shipment-sidebar.p-sidebar-right.p-sidebar-lg {
    width: 100%;
  }
}
.p-tabview-nav .tabview-nav-link-py-0 .p-tabview-nav-link {
  @apply py-0 h-full !important;
}

.reset-pointer-events .p-inputswitch-slider {
  cursor: pointer !important;
}

.p-multiselect .pi.pi-spin.pi-spinner {
  color: #3a3a3a;
}

.p-dropdown .pi.pi-spin.pi-spinner {
  color: #3a3a3a;
}

.p-autocomplete.w-full ul.p-autocomplete-multiple-container {
  width: 100%;
}

.autocomplete-clear {
  @apply absolute top-0 right-11 h-full flex items-center;
}

.clear-icon {
  @apply ml-1 cursor-pointer;
  color: #e2e8f0;
  right: 2.357rem;
}
.clear-icon:hover {
  color: #cbd5e0;
}

.p-dropdown-label.p-inputtext {
  box-shadow: none;
}

.table-header-filters .p-button.p-button-icon-only {
  @apply w-8;
}
.table-header-filters .p-autocomplete-dd .p-autocomplete-input {
  @apply pr-14;
}
.table-header-filters .p-multiselect-trigger {
  @apply w-8;
}
.table-header-filters .p-inputtext,
.table-header-filters .p-multiselect-label {
  @apply text-xs-plus py-1.5 px-1.5;
}
.table-header-filters .autocomplete-clear {
  @apply right-9;
}

.smaller-table-filters.table-header-filters .p-inputtext {
  @apply text-2xs px-1.5;
  font-family: var(--font-family);
  letter-spacing: -0.039em;
}
.smaller-table-filters.table-header-filters .p-button.p-button-icon-only {
  @apply w-6;
}
.smaller-table-filters.table-header-filters .p-button.p-component .pi,
.smaller-table-filters.table-header-filters .autocomplete-clear .pi {
  @apply text-xs;
}
.smaller-table-filters.table-header-filters .autocomplete-clear {
  @apply right-7;
}
.smaller-table-filters.table-header-filters .p-autocomplete-dd .p-autocomplete-input {
  @apply pr-11;
}
.smaller-table-filters.table-header-filters .clear-icon:hover {
  color: #a0aec0;
}

.p-confirm-dialog .p-dialog-content {
  @apply py-4;
  padding-right: 30px;
}
.p-confirm-dialog .p-dialog-header,
.p-confirm-dialog .p-dialog-footer {
  @apply py-4 px-6;
}

p-dynamicdialog .p-dialog-mask {
  top: 0px;
  bottom: 0px;
  height: auto;
  padding-top: 64px;
  padding-left: 14px;
  padding-right: 14px;
  background: rgba(0, 0, 0, 0.25) !important;
}

/* Theme specific customizations to the designer theme should be defined here */
.numbered-form .form-sections > *:last-child .form-section-header:after {
  content: none;
}
.numbered-form .form-section-header {
  font-family: var(--font-family);
  letter-spacing: -0.039em;
  @apply mb-5 text-2xl font-bold flex items-center;
}
.numbered-form .form-section-header:after {
  content: "";
  @apply absolute top-11 h-full;
  border-left: 2px solid var(--sectionNumberConnectorColor);
  left: calc((44px + 4px) / 2);
}
.numbered-form .form-section-header .section-number {
  @apply text-xl-plus font-bold rounded-full border-2 flex justify-center items-center box-content relative z-10;
  @apply text-primary-700 border-primary-700;
  background: var(--surface-a);
  width: 44px;
  height: 44px;
  margin-right: 32px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
}
.numbered-form .form-section-header.completed .section-number {
  @apply text-primary border-blue-500 bg-blue-500 overflow-hidden;
}
.numbered-form .form-section-header.completed .section-number:after {
  font-family: "primeicons", sans-serif;
  content: "\e909";
  @apply absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center  bg-blue-500 text-xl;
}
.numbered-form .form-section {
  margin-left: calc(44px + 32px + 4px);
}
.numbered-form .footer {
  padding-left: calc(44px + 32px + 4px);
}
.numbered-form .form-section-grid {
  @apply grid grid-cols-8 gap-7;
}
.numbered-form .form-section-input-group {
  @apply col-span-4 sm:col-span-4;
}
.numbered-form .form-section-input-group label {
  @apply text-sm-plus;
  font-family: var(--font-family);
  letter-spacing: -0.039em;
}

@media only screen and (max-width: 768px) {
  .numbered-form .form-section-grid {
    display: inherit;
  }
  .numbered-form .form-section-input-group {
    grid-column: inherit;
    display: block;
    margin-top: 10px;
  }
  .numbered-form .form-section {
    margin-left: 45px;
  }
  .section-number + div {
    font-size: 16px;
  }
  .numbered-form .footer {
    padding-left: 0;
    display: flex;
  }
  .numbered-form .footer button {
    font-size: 12px;
    padding: 2px 8px;
  }
  .numbered-form .form-section-header .section-number {
    width: 30px;
    height: 30px;
    font-size: 16px;
    margin-right: 12px;
  }
  .numbered-form .form-section-header:after {
    left: 17px;
  }
}
/* Non primeng related */
/* For all colors used here variable must be extracted to _variables to be overridable in theme */
.font-display {
  font-family: var(--font-family);
  letter-spacing: -0.039em;
}

html {
  background-color: #f6f6f6;
  width: 100vw;
  overflow-x: hidden;
  color: #111827;
  font-display: block;
}
html * {
  scroll-snap-margin-top: 6rem;
}

plu-root {
  height: 100vh;
  @apply flex flex-col;
}

.plu-container {
  @apply container max-w-7xl mx-auto px-4 sm:px-6;
}

h1 {
  @apply text-2xl mb-2 text-primary-800 font-semibold-minus;
  font-family: var(--font-family);
  letter-spacing: -0.039em;
}

h2 {
  @apply font-semibold-minus text-base text-primary-500;
  font-family: var(--font-family);
  letter-spacing: -0.039em;
}

h3 {
  @apply font-medium text-base text-primary-600;
}

.card {
  @apply px-7 py-6 mt-6 rounded-lg overflow-hidden w-full border;
  background-color: white;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.03);
}
.card .card-section {
  @apply px-7 py-6;
  @apply -mx-7;
}
.card .card-section:first-child {
  @apply -mt-6;
}
.card .card-section:last-child {
  @apply -mb-6;
}
.card .card-section + .card-section {
  @apply border-t;
}
.card .narrow-content > * {
  @apply block max-w-4xl mx-auto;
}
.card .vertical-form {
  @apply py-11 relative;
}
.card .vertical-form label {
  @apply block;
}
.card .vertical-form label:not(.p-checkbox-label, .p-radiobutton-label) {
  @apply mb-1;
}
.card .vertical-form .p-error {
  @apply block mt-1;
}
.card .vertical-form .required label {
  @apply font-bold;
}

@media only screen and (max-width: 991px) {
  .card {
    @apply px-4;
  }
  .card .card-section {
    @apply px-4 -mx-4;
  }
}
@media only screen and (max-width: 768px) {
  .card {
    @apply px-3.5;
  }
  .card .card-section {
    @apply px-3.5 -mx-3.5;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #949494;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #949494 transparent;
}

.hide-table-horizontal-scrollbar .p-datatable-wrapper {
  overflow: auto;
  scrollbar-width: none;
}
.hide-table-horizontal-scrollbar .p-datatable-wrapper::-webkit-scrollbar {
  height: 0px;
  width: 8px;
}
.hide-table-horizontal-scrollbar .p-datatable-wrapper::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}
.hide-table-horizontal-scrollbar .p-datatable-wrapper::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #949494;
}

.group-radio-button .p-radiobutton-box {
  border: 2px solid #4299e1 !important;
}
.group-radio-button .p-radiobutton-icon {
  background-color: #4299e1 !important;
}

.multiple-cargos-cell {
  @apply w-full grid grid-cols-1 auto-rows-fr;
}

/* Non primeng related */
.grid-settings-draggable-element {
  background-color: #edf2f7 !important;
}

.port-call-detail-card-item-text {
  color: #2d3748;
}

.port-call-detail-card-background {
  background-color: #e2e8f0;
}

.port-call-detail-map-toggle-container {
  background-color: #e2e8f0;
}